<template>
  <div>
    <div class="d-flex justify-content-end mt-3">
      <template v-if="this.data.projects.length > 0">
        <input
          :id="'fileImportExcel'"
          @input="onFileChange($event)"
          type="file"
          hidden
        />

        <a
          class="btn btn-light-warning font-weight-bolder font-size-sm mr-2"
          @click="importData"
          :disabled="loadingManhour"
          ref="kt_import_data"
        >
          <i class="fa fa-file-excel"></i>
          Import
        </a>

        <vue-excel-xlsx
          :data="data.exportList"
          :columns="columns"
          :file-name="fileNameExcel"
          :sheetname="'sheet1'"
        >
          <button
            class="btn btn-light-success font-weight-bolder font-size-sm mr-2"
            :disabled="data.exportList.length === 0"
          >
            <i class="fa fa-file-excel"></i>
            Export
          </button>
        </vue-excel-xlsx>
      </template>

      <b-button
        class="btn btn-success font-weight-bolder font-size-sm mr-2"
        @click="onSave()"
        ref="kt_simpan_man_hour"
        v-if="this.data.dld.length > 0"
      >
        <i class="flaticon2-check-mark" />Save
      </b-button>

      <button
        class="btn btn-info font-weight-bolder font-size-sm mr-2
"
        @click="onLoadDesign"
        ref="kt_load_design"
      >
        <i class="fa fa-sync" />Load Design
      </button>
    </div>

    <div class="table-responsive mt-3">
      <complete-table :loading="loadingDesign" :data="filteredDesign">
        <template #header>
          <tr class="text-left">
            <th style="min-width: 50px" class="pl-7">
              <span class="text-dark-75">No.</span>
            </th>
            <th></th>
            <th style="min-width: 200px" class="pl-7">
              <span class="text-dark-75">Title</span>
            </th>
            <th style="min-width: 80px" class="pl-7">
              <span class="text-dark-75">Scope</span>
            </th>
            <th style="min-width: 100px">Designer PIC</th>
            <th style="min-width: 100px">Designer Hour</th>
            <th style="min-width: 100px">Drafter PIC</th>
            <th style="min-width: 100px">Drafter Hour</th>
            <th style="min-width: 100px">Checker PIC</th>
            <th style="min-width: 100px">Checker Hour</th>
            <th style="min-width: 200px">Action</th>
          </tr>
        </template>

        <template #defaultBody="{ item, i, number }">
          <tr
            v-bind:key="i"
            :class="{ opened: opened.includes(item.numbering) }"
          >
            <td class="pl-0 ">
              <span
                class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
              >
                {{ number }}
              </span>
            </td>
            <td>
              <button
                class="btn btn-default btn-xs"
                @click="toggle(item.numbering)"
              >
                <span class="flaticon-eye"></span>
              </button>
            </td>

            <td class="pl-0 ">
              <span
                class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
              >
                {{ item.title }}
              </span>
            </td>
            <td class="pl-0 ">
              <span
                class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
              >
                {{ item.scope }}
              </span>
            </td>

            <td>
              <template v-if="currentIndex !== number - 1">
                <span class="text-muted font-weight-bold d-block">
                  {{ getPicName(item.designerPic.code) }}
                </span>
              </template>

              <template v-if="currentIndex === number - 1">
                <b-form-select
                  size="lg"
                  v-model="item.designerPic.code"
                  :options="currentPicOptions"
                ></b-form-select>
              </template>
            </td>

            <td>
              <template v-if="currentIndex !== number - 1">
                <span class="text-muted font-weight-bold d-block">
                  {{ item.designerHour }}
                </span>
              </template>

              <template v-if="currentIndex === number - 1">
                <b-form-input
                  type="number"
                  min="0.01"
                  v-model="item.designerHour"
                  no-wheel
                ></b-form-input>
              </template>
            </td>

            <td>
              <template v-if="currentIndex !== number - 1">
                <span class="text-muted font-weight-bold d-block">
                  {{ getPicName(item.drafterPic.code) }}
                </span>
              </template>

              <template v-if="currentIndex === number - 1">
                <b-form-select
                  size="lg"
                  v-model="item.drafterPic.code"
                  :options="currentPicOptions"
                ></b-form-select>
              </template>
            </td>

            <td>
              <template v-if="currentIndex !== number - 1">
                <span class="text-muted font-weight-bold d-block">
                  {{ item.drafterHour }}
                </span>
              </template>

              <template v-if="currentIndex === number - 1">
                <b-form-input
                  type="number"
                  min="0.01"
                  v-model="item.drafterHour"
                  no-wheel
                ></b-form-input>
              </template>
            </td>

            <td>
              <template v-if="currentIndex !== number - 1">
                <span class="text-muted font-weight-bold d-block">
                  {{ getPicName(item.checkerPic.code) }}
                </span>
              </template>

              <template v-if="currentIndex === number - 1">
                <b-form-select
                  size="lg"
                  v-model="item.checkerPic.code"
                  :options="currentPicOptions"
                ></b-form-select>
              </template>
            </td>

            <td>
              <template v-if="currentIndex !== number - 1">
                <span class="text-muted font-weight-bold d-block">
                  {{ item.checkerHour }}
                </span>
              </template>

              <template v-if="currentIndex === number - 1">
                <b-form-input
                  type="number"
                  min="0.01"
                  v-model="item.checkerHour"
                  no-wheel
                ></b-form-input>
              </template>
            </td>

            <td class="pr-0">
              <template v-if="currentIndex !== number - 1">
                <a
                  class="btn btn-light-success font-weight-bolder font-size-sm"
                  @click="edit(number - 1)"
                >
                  <i class="flaticon-edit"></i>
                </a>
              </template>

              <template v-if="currentIndex === number - 1">
                <a
                  class="btn btn-light-success font-weight-bolder font-size-sm"
                  @click="update(item.id)"
                >
                  <i class="flaticon2-check-mark"></i>
                </a>

                <a
                  class="btn btn-light-danger font-weight-bolder font-size-sm ml-3"
                  @click="cancelEdit()"
                >
                  <i class="flaticon2-cancel-music"></i>
                </a>
              </template>
            </td>
          </tr>

          <tr v-bind:key="1000 + i" v-if="opened.includes(item.numbering)">
            <td colspan="12">
              <template>
                <div>
                  <div class="card p-5 gutter-b">
                    <div class="card-body p-0">
                      <div class="row">
                        <div class="col-md-3 ">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >Numbering : </span
                          ><span class="text-dark-75">{{
                            item.numbering
                          }}</span>
                        </div>
                        <div class="col-md-3 ">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >Type : </span
                          ><span class="text-dark-75">{{ item.type }}</span>
                        </div>
                        <div class="col-md-3 ">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >Station : </span
                          ><span class="text-dark-75">{{ item.station }}</span>
                        </div>
                        <div class="col-md-3 ">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >Approved : </span
                          ><span class="text-dark-75">{{ item.approved }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3 ">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >Category : </span
                          ><span class="text-dark-75">{{ item.category }}</span>
                        </div>
                        <div class="col-md-3 ">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >Status : </span
                          ><span class="text-dark-75">{{ item.status }}</span>
                        </div>
                        <div class="col-md-3 ">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >Outstanding : </span
                          ><span class="text-dark-75">{{
                            item.outstanding.name
                          }}</span>
                        </div>
                        <div class="col-md-3 ">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >Return Item: </span
                          ><span class="text-dark-75">
                            {{ item.returnItem }}</span
                          >
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-3 ">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >Revision : </span
                          ><span class="text-dark-75">
                            {{ item.revision }}</span
                          >
                        </div>
                        <div class="col-md-3 ">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >Version : </span
                          ><span class="text-dark-75">{{ item.version }}</span>
                        </div>
                        <div class="col-md-3 ">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >Date Receive : </span
                          ><span class="text-dark-75">{{
                            moment(item.dateReceive).isValid()
                              ? moment(item.dateReceive).format("DD-MM-YYYY")
                              : ""
                          }}</span>
                        </div>
                        <div class="col-md-3 ">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >Asbuilt : </span
                          ><span class="text-dark-75">{{ item.asbuilt }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3 ">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >Delay : </span
                          ><span class="text-dark-75">{{ item.delay }}</span>
                        </div>
                        <div class="col-md-3 ">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >Storage Doc: </span
                          ><span class="text-dark-75">
                            {{ item.storageRuangDoc }}</span
                          >
                        </div>
                        <div class="col-md-3 ">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >Server : </span
                          ><span class="text-dark-75">{{ item.server }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </td>
          </tr>
        </template>
      </complete-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  GET_MAN_HOUR_FOR,
  CLEAN_STATE,
  POST_MAN_HOUR,
} from "@/core/services/store/manhour.module.js";
import { GET_OUTSTANDING } from "@/core/services/store/outstanding.module";
import { GET_PIC } from "@/core/services/store/pic.module";
import CompleteTable from "../../../content/widgets/advance-table/CompleteTable";
import readXlsxFile from "read-excel-file";
import { showToast } from "@/core/helper/toast.helper";
import { arrayMoreThanOne } from "../../../../core/helper/array-validation.helper";

export default {
  name: "Software",
  components: {
    CompleteTable,
  },
  props: {
    project: {
      required: true,
    },
    dataFilter: {
      required: true,
    },
  },
  data() {
    return {
      opened: [],
      currentIndex: null,
      oldData: {},
      data: {
        dld: [],
        projects: [],
        exportList: [],
      },
      pagination: {
        page: 1,
        total: 0,
        rowsPerPage: 5,
      },
      search: "",
      columns: [
        {
          label: "ID",
          field: "id",
        },
        {
          label: "Title",
          field: "title",
        },
        {
          label: "Numbering",
          field: "numbering",
        },
        {
          label: "Designer PIC (Changeable)",
          field: "designerPIC",
        },
        {
          label: "Designer Hour (Changeable)",
          field: "designerHour",
        },
        {
          label: "Drafter PIC (Changeable)",
          field: "drafterPIC",
        },
        {
          label: "Drafter Hour (Changeable)",
          field: "drafterHour",
        },
        {
          label: "Checker PIC (Changeable)",
          field: "checkerPIC",
        },
        {
          label: "Checker Hour (Changeable)",
          field: "checkerHour",
        },
      ],
    };
  },
  methods: {
    onFileChange(event) {
      let xlsxfile = event.target.files ? event.target.files[0] : null;
      readXlsxFile(xlsxfile).then((rows) => {
        this.onImportData(rows);
      });
    },

    onImportData(listData) {
      let dataSubmit = [];
      for (let i = 1; i < listData.length; i++) {
        dataSubmit.push({
          id: listData[i][0],
          designer_pic: {
            code: listData[i][3],
          },
          designer_hour: listData[i][4],
          drafter_pic: {
            code: listData[i][5],
          },
          drafter_hour: listData[i][6],
          checker_pic: {
            code: listData[i][7],
          },
          checker_hour: listData[i][8],
        });
      }

      const submitButton = this.$refs["kt_import_data"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      setTimeout(() => {
        this.$store
          .dispatch(POST_MAN_HOUR, { dld: dataSubmit })
          .then(() => {
            this.onLoadDesign();
            showToast("Success", "Upload Success!");
          })
          .catch(() => {
            showToast("Failed", "Failed import data", "danger");
          });

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    },
    doReset() {
      this.onLoadDesign();
    },
    importData() {
      document.getElementById("fileImportExcel").click();
    },
    doSearch(dataFilter) {
      if (this.data.projects === undefined || this.data.projects.length === 0) {
        this.$bvToast.toast("please load design first.", {
          title: "Warning",
          variant: "warning",
          solid: true,
        });
      } else {
        if (
          dataFilter.station !== null &&
          dataFilter.scope === null &&
          dataFilter.title === null
        ) {
          this.data.projects = this.data.projects.filter(
            (c) => c.station === dataFilter.station
          );
        } else if (
          dataFilter.station === null &&
          dataFilter.scope !== null &&
          dataFilter.title === null
        ) {
          this.data.projects = this.data.projects.filter(
            (c) => c.scope === dataFilter.scope
          );
        } else if (
          dataFilter.station === null &&
          dataFilter.scope === null &&
          dataFilter.title !== null
        ) {
          this.data.projects = this.data.projects.filter((c) =>
            c.title?.toUpperCase()?.includes(dataFilter.title.toUpperCase())
          );
        } else if (
          dataFilter.station !== null &&
          dataFilter.scope !== null &&
          dataFilter.title === null
        ) {
          this.data.projects = this.data.projects.filter(
            (c) =>
              c.station === dataFilter.station && c.scope === dataFilter.scope
          );
        } else if (
          dataFilter.station === null &&
          dataFilter.scope !== null &&
          dataFilter.title !== null
        ) {
          this.data.projects = this.data.projects.filter(
            (c) =>
              c.title
                ?.toUpperCase()
                ?.includes(dataFilter.title.toUpperCase()) &&
              c.scope === dataFilter.scope
          );
        } else if (
          dataFilter.station !== null &&
          dataFilter.scope === null &&
          dataFilter.title !== null
        ) {
          this.data.projects = this.data.projects.filter(
            (c) =>
              c.title
                ?.toUpperCase()
                ?.includes(dataFilter.title.toUpperCase()) &&
              c.scope === dataFilter.scope
          );
        } else if (
          dataFilter.station !== null &&
          dataFilter.scope !== null &&
          dataFilter.title !== null
        ) {
          this.data.projects = this.data.projects.filter(
            (c) =>
              c.title
                ?.toUpperCase()
                ?.includes(dataFilter.title.toUpperCase()) &&
              c.scope === dataFilter.scope &&
              c.station === dataFilter.station
          );
        } else if (
          dataFilter.title === "" &&
          dataFilter.station === null &&
          dataFilter.scope === null
        ) {
          this.onLoadDesign();
        }
      }
    },
    edit(index) {
      this.currentIndex = index;
      //this.oldData = this.data.projects[index];
      this.oldData = this.data.projects[index];
    },

    remove(index) {
      //this.data.projects.splice(index, 1);
      this.data.projects.splice(index, 1);
    },

    cancelEdit() {
      //this.data.projects[this.currentIndex] = this.oldData;
      this.data.projects[this.currentIndex] = this.oldData;
      this.currentIndex = null;
    },

    update(id) {
      if (this.dataFilter.station == null) {
        this.$bvToast.toast("Please Choose Project Station.", {
          title: "Warning",
          variant: "warning",
          solid: true,
        });
      }

      var project = this.data.projects.filter((c) => c.id === id)[0];
      if (project !== null) {
        if (this.data.dld.filter((c) => c.id === project.id).length > 0) {
          this.data.dld = this.data.dld.filter((c) => c.id !== project.id);
        }

        this.data.dld.push({
          id: project.id,
          designer_pic: {
            code: project.designerPic.code,
            name: project.designerPic.name,
          },
          designer_hour: project.designerHour,
          drafter_pic: {
            code: project.drafterPic.code,
            name: project.drafterPic.name,
          },
          drafter_hour: project.drafterHour,
          checker_pic: {
            code: project.checkerPic.code,
            name: project.checkerPic.name,
          },
          checker_hour: project.checkerHour,
          outstanding: {
            code: project.outstanding.code,
            name: project.outstanding.name,
          },
        });
      }
      this.currentIndex = null;
    },

    loadDesign(projectID) {
      const submitButton = this.$refs["kt_load_design"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      this.data.dld = [];
      setTimeout(() => {
        this.$store
          .dispatch(GET_MAN_HOUR_FOR, {
            project_id: projectID,
          })
          .then(() => this.mappingDocumentObject(this.currentManHourFor));

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 1000);
    },

    cleanDocument() {
      this.$store.dispatch(CLEAN_STATE, "FOR");
      this.data.projects = [];
    },

    getPicName(code) {
      var result = this.currentPicOptions.find((x) => x.value === code);
      if (result !== undefined) return result.text;

      return "";
    },
    getOutstandingName(code) {
      var result = this.currentOutstandingOptions.find((x) => x.value === code);
      if (result !== undefined) return result.text;

      return "";
    },
    mappingDocumentObject(listDocument) {
      this.data.projects = [];
      this.data.exportList = [];
      listDocument.map((doc) => {
        this.data.projects.push({
          id: doc.id,
          numbering: doc.numbering,
          title: doc.title,
          type: doc.type ? doc.type.name : null,
          category: doc.category ? doc.category.name : null,
          scope: doc.scope ? doc.scope.name : null,
          station: doc.station ? doc.station.name : null,
          designerPic: {
            code: doc.designer_pic ? doc.designer_pic.code : null,
            name: doc.designer_pic ? doc.designer_pic.name : null,
          },
          designerHour: doc.designer_hour,
          drafterPic: {
            code: doc.drafter_pic ? doc.drafter_pic.code : null,
            name: doc.drafter_pic ? doc.drafter_pic.name : null,
          },
          drafterHour: doc.drafter_hour,
          checkerPic: {
            code: doc.checker_pic ? doc.checker_pic.code : null,
            name: doc.checker_pic ? doc.checker_pic.name : null,
          },
          checkerHour: doc.checker_hour,
          approved: doc.approved,
          planingDate: doc.planning_date,
          status: doc.status,
          revision: doc.revision_version,
          version: doc.version,
          dateReceive: doc.date_receive,
          delay: doc.delay,
          storageRuangDoc: doc.storageRuangDoc,
          server: doc.server,
          asbuilt: doc.monitoring_asbuilt_status,
          returnItem: doc.return_item,
          outstanding: {
            code: doc.outstanding ? doc.outstanding.code : null,
            name: doc.outstanding ? doc.outstanding.name : null,
          },
        });

        this.data.exportList.push({
          id: doc.id,
          title: doc.title,
          numbering: doc.numbering,
          designerPIC: doc.designer_pic ? doc.designer_pic.code : "",
          designerHour: doc.designer_hour,
          drafterPIC: doc.drafter_pic ? doc.drafter_pic.code : null,
          drafterHour: doc.drafter_hour,
          checkerPIC: doc.checker_pic ? doc.checker_pic.code : null,
          checkerHour: doc.checker_hour,
        });
      });
    },

    onLoadDesign() {
      if (this.project.code === null) {
        this.$bvToast.toast("please project first.", {
          title: "Warning",
          variant: "warning",
          solid: true,
        });
      } else {
        this.loadDesign(this.project.code);
      }
    },
    toggle(id) {
      const index = this.opened.indexOf(id);
      if (index > -1) {
        this.opened.splice(index, 1);
      } else {
        this.opened.push(id);
      }
    },
    onSave() {
      // set spinner to submit button
      if (this.data.dld.length > 0) {
        const submitButton = this.$refs["kt_simpan_man_hour"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");
        setTimeout(() => {
          this.$store
            .dispatch(POST_MAN_HOUR, {
              project: this.project,
              dld: this.data.dld,
            })
            .then(() => this.loadDesign(this.project.code));

          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        }, 2000);
      } else {
        this.$bvToast.toast("nothing change!.", {
          title: "Warning",
          variant: "warning",
          solid: true,
        });
      }
    },
  },
  computed: {
    ...mapGetters([
      "getterManHourFor",
      "loadingDesign",
      "currentPics",
      "currentOutstanding",
      "currentLoadingManHour",
    ]),
    fileNameExcel() {
      return (
        this.project.code +
        "-" +
        this.project.nickname +
        "-" +
        "Software" +
        "  Mah Hour"
      );
    },
    loadingManhour() {
      return this.currentLoadingManHour;
    },

    currentManHourFor() {
      return this.getterManHourFor;
    },

    currentIdProject() {
      return this.project.code;
    },

    currentPicOptions() {
      let pics = [];

      if (arrayMoreThanOne(this.currentPics)) {
        pics = this.currentPics.map((pic) => {
          return {
            value: pic.code,
            text: pic.code + "-" + pic.name,
          };
        });
      }

      return [{ value: null, text: "" }, ...pics];
    },

    currentOutstandingOptions() {
      return this.currentOutstanding.map((ot) => {
        return {
          value: ot.code,
          text: ot.code + "-" + ot.name,
        };
      });
    },

    filterItems() {
      return this.data.projects.filter((items) => {
        return items.numbering.match(this.search);
      });
    },

    filteredDesign() {
      const station = this.dataFilter.station;
      const scope = this.dataFilter.scope;
      const title = this.dataFilter.title;

      return this.data.projects?.filter(
        (design) =>
          (station ? design.station === station : true) &&
          (scope ? design.scope === scope : true) &&
          (title
            ? design.title?.toLowerCase()?.includes(title?.toLowerCase())
            : true)
      );
    },
  },
  async created() {
    await this.$store.dispatch(GET_PIC);
    await this.$store.dispatch(GET_OUTSTANDING);
  },
  watch: {
    currentIdProject(value) {
      if (value === null) {
        this.cleanDocument();
      }
    },
  },
};
</script>
