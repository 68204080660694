<template>
  <div>
    <div class="table-loader-man-hour">
      <div
        class="text-center"
        v-bind:class="{
          'd-none': loadingActive
        }"
      >
        <div class="text-center">
          <div class="spinner-border text-secondary" role="status">
            <span class="visually-hidden"></span>
          </div>
        </div>
      </div>
    </div>

    <div
      v-bind:class="{
        'd-none': tableManHour
      }"
    >
      <div class="card card-custom p-5 gutter-b">
        <div class="card-body p-0">
          <div class="row">
            <div class="col-md-6 py-2 col-sm-12">
              <label for="input-large">Nickname</label>

              <b-form-select
                id="input-lg"
                value-field="nickname"
                text-field="nickname"
                size="lg"
                v-model="selectNickname"
              >
                <b-form-select-option
                  v-for="(proj, i) in this.projectList"
                  v-bind:key="i"
                  :value="{ code: proj.code, text: proj.nickname }"
                >
                  {{ proj.nickname }}
                </b-form-select-option>
              </b-form-select>
            </div>

            <div class="col-md-6 py-2 col-sm-12">
              <label for="input-large">Owner</label>

              <b-form-input
                id="input-large"
                size="lg"
                disabled
                placeholder="Owner"
                v-model="selectedNickname.ownerName"
              ></b-form-input>
            </div>

            <div class="col-md-6 py-2 col-sm-12">
              <label for="input-large">Project Name</label>

              <b-form-input
                id="input-large"
                size="lg"
                disabled
                placeholder="Project Name"
                v-model="selectedNickname.projectName"
              ></b-form-input>
            </div>

            <div class="col-md-6 py-2 col-sm-12">
              <label for="input-large">Contract Number</label>

              <b-form-input
                id="input-large"
                size="lg"
                disabled
                placeholder="Contract Number"
                v-model="selectedNickname.contractNumber"
              ></b-form-input>
            </div>

            <div class="col-md-6 py-2 col-sm-12">
              <label for="input-large">Status</label>

              <b-form-input
                disabled
                v-model="selectedNickname.status"
                id="input-large"
                size="lg"
                placeholder="Status"
              ></b-form-input>
            </div>
          </div>
        </div>
      </div>

      <div class="card card-custom p-5 gutter-b">
        <div class="card-body p-0">
          <div class="row">
            <div class="col-md-6 py-2 col-sm-12">
              <label for="input-large">Station</label>

              <b-form-select
                v-model="filter.station"
                :options="options.stations"
                size="lg"
              ></b-form-select>
            </div>

            <div class="col-md-6 py-2 col-sm-12">
              <label for="input-large">Scope</label>
              <b-form-select
                v-model="filter.scope"
                :options="scopeOptions"
                size="lg"
              ></b-form-select>
            </div>

            <div class="col-md-6 py-2 col-sm-12">
              <label for="input-large">Title</label>

              <b-form-input
                id="input-large"
                size="lg"
                placeholder="Title"
                v-model="filter.title"
              ></b-form-input>
            </div>
          </div>

          <div class="d-flex justify-content-end">
            <button class="btn btn-success btn-md mr-2" @click="onResetData">
              <i class="flaticon2-refresh"></i> Reset
            </button>
          </div>
        </div>
      </div>

      <div class="card card-custom p-5 gutter-b">
        <div class="card-body p-0">
          <b-tabs content-class="mt-3" v-model="selectedTabName">
            <b-tab title="Document">
              <document
                :project="selectedNickname"
                ref="docRef"
                :dataFilter="filter"
              ></document>
            </b-tab>

            <b-tab title="Drawing">
              <drawing
                :project="selectedNickname"
                ref="drwRef"
                :dataFilter="filter"
              ></drawing>
            </b-tab>

            <b-tab title="Software">
              <software
                :project="selectedNickname"
                ref="softRef"
                :dataFilter="filter"
              ></software>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Document from "./man-hour/Document.vue";
import Drawing from "./man-hour/Drawing.vue";
import Software from "./man-hour/Software.vue";
import { GET_SCOPES } from "@/core/services/store/scope.module";
import { GET_STATION_BY_PROJECT } from "@/core/services/store/station.module";
import {
  SHOW_PROJECT_ADMIN,
  SHOW_PROJECT_KOOR
} from "@/core/services/store/dl.design.module.js";
import {
  FILTER_PROJECT_DETAIL
} from "@/core/services/store/project.module";

import { mapGetters } from "vuex";

export default {
  name: "ManHour",
  components: {
    Document,
    Drawing,
    Software
  },
  data() {
    return {
      filter: {
        status: null,
        name: null,
        code: null,
        owner: null,
        contractNumber: null,
        nickname: null,
        station: null,
        scope: null,
        title: null
      },
      selectedNickname: {
        code: null,
        ownerName: null,
        projectName: null,
        status: null,
        contractNumber: null
      },
      selectNickname: {
        code: null,
        text: null,
      },
      selectedTabName: "",
      options: {
        statuses: [
          { value: null, text: "Choose Status" },
          { value: "open", text: "Open" },
          { value: "close", text: "Close" }
        ],
        nicknames: [{ value: null, text: "Choose Project" }],
        stations: [{ value: null, text: "Choose Station" }],
        scopes: [{ value: null, text: "Choose Scope" }]
      },
      data: {
        projects: [
          {
            code: "LR98123",
            name: "PEMBANGUNAN SISTEM PERSINYALAN",
            status: "Open",
            owner: "BALAI TEKNIK PERKERETAAPIAN"
          }
        ]
      },
      loadingActive: false,
      tableManHour: true
    };
  },
  methods: {
    onFilterData() {
      console.log("tabName", this.selectedTabName);
      if (this.selectedTabName === 0) {
        this.$refs.docRef.doSearch(this.filter);
      } else if (this.selectedTabName === 1) {
        this.$refs.drwRef.doSearch(this.filter);
      } else if (this.selectedTabName === 2) {
        this.$refs.softRef.doSearch(this.filter);
      }
    },

    merge(a, b, prop) {
      var reduced = a.filter(
        (aitem) => !b.find((bitem) => aitem[prop] === bitem[prop])
      );
      return reduced.concat(b);
    },

    onResetData() {
      Object.assign(this.filter, {
        station: null,
        scope: null,
        title: null
      });

      if (this.selectedTabName === 0) {
        this.$refs.docRef.doReset();
      } else if (this.selectedTabName === 1) {
        this.$refs.drwRef.doReset();
      } else if (this.selectedTabName === 2) {
        this.$refs.softRef.doReset();
      }
    },

    getProjectByNickName(nickname) {
      const filter = {
        jobdesk: "koor",
        params: {
          nickname: nickname === null ? "" : nickname
        }
      };
      this.$store.dispatch(FILTER_PROJECT_DETAIL, filter);
    },

    getStationByProject(projectID) {
      this.$store.dispatch(GET_STATION_BY_PROJECT, projectID).then(() =>
        this.currentStationProject.stations.map(stationProject => {
          this.options.stations.push({
            value: stationProject.name,
            text: stationProject.name
          });
        })
      );
    },

  },
  async created() {
    await this.$store.dispatch(SET_BREADCRUMB, [{ title: "Man Hour" }]);
    await this.$store.dispatch(GET_SCOPES);

    await this.$store.dispatch(SHOW_PROJECT_ADMIN);
    await this.$store.dispatch(SHOW_PROJECT_KOOR);
    
    this.loadingActive = true;
    this.tableManHour = false;
  },
  computed: {
    ...mapGetters([
      "currentScopes",
      "currentStationProject",
      "projectNicknameOptions",
      "currentProjectDetails",
      "getterManHourDoc"
    ]),
    scopeOptions() {
      var result = [{ value: null, text: "Choose Scope" }];
      this.currentScopes.map(scope => {
        result.push({
          value: scope.name,
          text: scope.name
        });
      });
      return result;
    },
    currentNickname() {
      return this.selectNickname.code;
    },
    currentManHourDoc() {
      return this.getterManHourDoc;
    },
    projectList() {
      let projectAdmin = this.$store.getters.getterProjectAdmin;
      let projectKoor = this.$store.getters.getterProjectKoor;
      return this.merge(projectAdmin, projectKoor, "code");
    }

  },
  watch: {
    currentNickname(value) {
      if (!value) {
        Object.assign(this.selectedNickname, {
          code: null,
          ownerName: null,
          projectName: null,
          status: null,
          contractNumber: null
        });

        this.options.stations = [{ value: null, text: "Choose Station" }];

        return;
      }

      const project = this.projectList.find(
        x => x.code === this.selectNickname.code
      );

      Object.assign(this.selectedNickname, {
        ownerName: project?.owner,
        projectName: project?.name,
        status: project?.status,
        contractNumber: project?.contract_no,
        ...project
      });

      this.getStationByProject(this.selectNickname.code);
    }
  }
};
</script>

<style></style>
